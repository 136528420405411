import {ArrayUtil} from 'helper-util'
import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {
	getAbcPortfolioQuery,
	getAbcFiltersQuery,
	getAbcFiltersQueryNew,
	buildAbcPortfolioQuery,
	fetchSearchSecurityResultQuery,
	fetchLocateSearchBucketListQuery,
	searchAbcmAndUpdateQueue,
} from './abcPortfolioQueries'
import {post, get} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_ABC_PORTFOLIO = createFetchActions('GET_ABC_PORTFOLIO')
export const FETCH_ABC_PORTFOLIO_COUNT = createFetchActions('ABC_PORTFOLIO_COUNT')
export const GET_ABC_PORTFOLIO_FILTER = createFetchActions('GET_ABC_PORTFOLIO_FILTER')
export const SET_ABC_PORTFOLIO_GROUP_FILTERS = 'SET_ABC_PORTFOLIO_GROUP_FILTERS'
export const SET_ABC_PORTFOLIO_SELECTED_VIEW = 'SET_ABC_PORTFOLIO_SELECTED_VIEW'
export const RESET_ABC_PORTFOLIO_DATA = 'RESET_ABC_PORTFOLIO_DATA'
export const RESET_ABC_PORTFOLIO_FILTERS = 'RESET_ABC_PORTFOLIO_FILTERS'
export const RESET_ABC_PORTFOLIO_LOADER = 'RESET_ABC_PORTFOLIO_LOADER'
export const STOP_ABC_PORTFOLIO_LOADER = 'STOP_ABC_PORTFOLIO_LOADER'
export const FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM = createFetchActions('FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM')
export const SUBMIT_LOAN_FROM_ABCM_PORTFOLIO = createFetchActions('SUBMIT_LOAN_FROM_ABCM_PORTFOLIO')
export const SET_ABCM_LOCATE_DATA_INTO_QUEUE_FROM_LOCATE_CLIENT = createFetchActions('createFetchActions')
export const GET_ABCM_LOCATE_CLIENT_SEARCH_RESULT = createFetchActions('GET_ABCM_LOCATE_CLIENT_SEARCH_RESULT')
export const FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE = createFetchActions(
	'FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE'
)
export const ADD_AVAILABILITY_FROM_ABCM_LOCATE = createFetchActions('ADD_AVAILABILITY_FROM_ABCM_LOCATE')
export const SAVE_LOCATE_SEARCH_RESULT_FROM_ABCM = createFetchActions('SAVE_LOCATE_SEARCH_RESULT_FROM_ABCM')
export const EMPTY_LOCATE_COMPLETE_QUEUE_RESULT_FROM_ABCM = createFetchActions(
	'EMPTY_LOCATE_COMPLETE_QUEUE_RESULT_FROM_ABCM'
)

export const GET_ABC_PORTFOLIO_FILTER_NEW = createFetchActions('GET_ABC_PORTFOLIO_FILTER_NEW')

export const setAbcPortfolioGroupFilters = data => {
	return {
		type: SET_ABC_PORTFOLIO_GROUP_FILTERS,
		response: {
			data,
		},
	}
}

export const setAbcPortfolioSelectedView = data => {
	return {
		type: SET_ABC_PORTFOLIO_SELECTED_VIEW,
		response: {
			data,
		},
	}
}

export const getAbcPortfolioData = (page: any, size: any, formattedFilters: any) => {
	return function (dispatch) {
		dispatch({
			type: GET_ABC_PORTFOLIO.triggered,
		})
		getPMSClient()
			.query({
				query: getAbcPortfolioQuery(),
				fetchPolicy: 'network-only',
				variables: {
					page,
					size,
					filters: formattedFilters,
				},
			})
			.then(response => {
				dispatch({
					type: GET_ABC_PORTFOLIO.succeeded,
					response,
				})
			})
	}
}

export const getAbcPortfolioCount = () => (dispatch: any) => {
	return dispatch(
		get(
			FETCH_ABC_PORTFOLIO_COUNT,
			`${getPMSBaseUrl()}/v1/portfolios/portfolio-entry-count`,
			null,
			null,
			'Error occured while getting abc portfolio count'
		)
	)
}

export const getAbcPortfolioFilterValues = (field: any, filter: any, groupKey: any) => {
	let variables = !ArrayUtil.isEmpty(filter)
		? {
				field,
				filter,
		  }
		: {
				field,
		  }

	return function (dispatch) {
		dispatch({
			type: GET_ABC_PORTFOLIO_FILTER.triggered,
		})
		getPMSClient()
			.query({
				query: getAbcFiltersQuery(groupKey),
				fetchPolicy: 'network-only',
				variables,
			})
			.then(response => {
				dispatch({
					type: GET_ABC_PORTFOLIO_FILTER.succeeded,
					response,
					groupKey,
				})
			})
	}
}

export const getAbcPortfolioFilterValuesNew = (field: any, filter: any, groupKey: any) => {
	let variables = !ArrayUtil.isEmpty(filter)
		? {
				field,
				filter,
		  }
		: {
				field,
		  }

	return function (dispatch) {
		dispatch({
			type: GET_ABC_PORTFOLIO_FILTER_NEW.triggered,
		})
		getPMSClient()
			.query({
				query: getAbcFiltersQueryNew(),
				fetchPolicy: 'network-only',
				variables,
			})
			.then(response => {
				dispatch({
					type: GET_ABC_PORTFOLIO_FILTER_NEW.succeeded,
					response,
					groupKey,
				})
			})
	}
}

export const resetAbcPortfolioData = () => {
	return {
		type: RESET_ABC_PORTFOLIO_DATA,
	}
}

export const resetAbcPortfolioFilters = () => {
	return {
		type: RESET_ABC_PORTFOLIO_FILTERS,
	}
}

export const resetAbcPortfolioLoader = () => {
	return {
		type: RESET_ABC_PORTFOLIO_LOADER,
	}
}

export const getAbcPortfolioDataBasedOnSelectedColumns = (
	page: any,
	size: any,
	formattedFilters: any,
	selectedColumndefs: any
) => {
	return function (dispatch) {
		dispatch({
			type: GET_ABC_PORTFOLIO.triggered,
		})
		getPMSClient()
			.query({
				query: buildAbcPortfolioQuery(selectedColumndefs),
				fetchPolicy: 'network-only',
				variables: {
					page,
					size,
					filters: formattedFilters,
				},
			})
			.then(response => {
				dispatch({
					type: GET_ABC_PORTFOLIO.succeeded,
					response,
				})
			})
	}
}

export const stopTableLoader = () => {
	return {
		type: STOP_ABC_PORTFOLIO_LOADER,
	}
}

export const getLocateSearchResult = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM.triggered,
		})
		getPMSClient()
			.query({
				query: fetchSearchSecurityResultQuery(data),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM.succeeded,
					response,
				})
			})
	}
}

export const submitLoan = (data: any, id: string) => (dispatch: any) => {
	return dispatch(
		post(SUBMIT_LOAN_FROM_ABCM_PORTFOLIO, `${getPMSBaseUrl()}/v1/availabilities-needs/${id}/orders`, data)
	)
}

export const setAbcmLocateDataIntoQueue = (data: any) => (dispatch: any) => {
	return dispatch(
		post(
			SET_ABCM_LOCATE_DATA_INTO_QUEUE_FROM_LOCATE_CLIENT,
			`${getPMSBaseUrl()}/v1/locate/queue`,
			data,
			null,
			'Error occured while setting locate data into queue from abcm portfolio'
		)
	)
}

export const getAbcmLocateSearchBucketResult = data => {
	return function (dispatch) {
		dispatch({
			type: GET_ABCM_LOCATE_CLIENT_SEARCH_RESULT.triggered,
		})
		return getPMSClient().query({
			query: fetchLocateSearchBucketListQuery(data),
			fetchPolicy: 'network-only',
		})
	}
}

export const getAbcmLocateSearchResultAndUpdateQueue = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE.triggered,
		})
		getPMSClient()
			.query({
				query: searchAbcmAndUpdateQueue(data),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE.succeeded,
					response,
				})
			})
	}
}

export const addAvailabilityFromLocate = req => (dispatch: any) => {
	return dispatch(
		post(ADD_AVAILABILITY_FROM_ABCM_LOCATE, `${getPMSBaseUrl()}/v1/availabilities-needs/add-availability`, req)
	)
}

export const saveLocateData = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_LOCATE_SEARCH_RESULT_FROM_ABCM, `${getPMSBaseUrl()}/v1/locate/authorize`, data))
}

export const emptyLocateCompleteRequestQueueData = () => {
	return {type: EMPTY_LOCATE_COMPLETE_QUEUE_RESULT_FROM_ABCM}
}
