import {CLIENT_CONSTANTS} from '../../constants/clientConfig'
import {protectedRouteList, paths} from '../../routes/routesConfig'

const CoreConfigMenuItems = [
	{
		...protectedRouteList.portfolioManager,
		subMenu: [protectedRouteList.uploadPortfolio, protectedRouteList.securityManager],
	},
	protectedRouteList.dashboardTest,
	{
		...protectedRouteList.analytics,
		subMenu: [
			protectedRouteList.marginAnalysis,
			protectedRouteList.marginAttribution,
			protectedRouteList.abcmAnalytics,
			protectedRouteList.abcPortfolioTest,
			// protectedRouteList.inventory,
			protectedRouteList.settlementLadder,
			protectedRouteList.settlementCalendar,
			protectedRouteList.sblEfficiency,
		],
	},
	{
		...protectedRouteList.quantitativeIntelligence,
		subMenu: [
			protectedRouteList.decisionMaking,
			protectedRouteList.simm,
			protectedRouteList.clientScoring,
			protectedRouteList.collateralOptimization,
			protectedRouteList.collateralOptimizationDashboard,
		],
	},
	{
		...protectedRouteList.portfolioAnalytics,
		subMenu: [
			protectedRouteList.indexAnalysis,
			protectedRouteList.inventoryOptimization,
			protectedRouteList.collateralBasketList,
		],
	},
	{
		...protectedRouteList.execution,
		subMenu: [
			protectedRouteList.tradingQueue,
			protectedRouteList.peerToPeer,
			protectedRouteList.marketPlace,
			protectedRouteList.marginManagement,
			protectedRouteList.needsAvailabilities,
			{...protectedRouteList.group, label: 'Locates'},
			protectedRouteList.alp,
			protectedRouteList.alpInventoryManager,
			protectedRouteList.alpSecurityManager,
			protectedRouteList.alpClient,
			protectedRouteList.repo,
			protectedRouteList.sbl,
			protectedRouteList.intradayActivities,
			protectedRouteList.tradeBlotter,
		],
	},
	{
		...protectedRouteList.dataMining,
		subMenu: [protectedRouteList.marketData],
	},
	protectedRouteList.operations,
	{
		...protectedRouteList.pricingTool,
		subMenu: [protectedRouteList.indexPricer, protectedRouteList.breakevenPricer],
	},
	{
		...protectedRouteList.templateManagement,
		subMenu: [
			protectedRouteList.dmnViewer,
			protectedRouteList.cusipSchedule,
			protectedRouteList.lcr,
			protectedRouteList.abpm,
		],
	},
]

export const navigationMenu = {
	[CLIENT_CONSTANTS.FINOPTSYS]: CoreConfigMenuItems,
	[CLIENT_CONSTANTS.TRADEUP]: CoreConfigMenuItems,
	[CLIENT_CONSTANTS.STATESTREET]: [
		{...protectedRouteList.uploadPortfolio, label: 'Inventory Upload'},
		protectedRouteList.dashboardTest,
		{
			...protectedRouteList.analytics,
			label: 'Portfolio View',
			subMenu: [protectedRouteList.abcmAnalytics, protectedRouteList.abcPortfolioTest],
		},
		{
			...protectedRouteList.portfolioAnalytics,
			label: 'Portfolio Tools',
			subMenu: [protectedRouteList.collateralBasketList],
		},
		{
			...{...protectedRouteList.execution, label: 'Peer-To-Peer Portal'},
			subMenu: [
				// protectedRouteList.tradingQueue,
				{...protectedRouteList.peerToPeer, label: 'P2P Repo'},
				protectedRouteList.marginManagement,
				protectedRouteList.needsAvailabilities,
				{...protectedRouteList.group, label: 'ALP'},
				protectedRouteList.alp,
				protectedRouteList.alpInventoryManager,
				protectedRouteList.alpSecurityManager,
				protectedRouteList.alpClient,
				// protectedRouteList.repo,
			],
		},
		{
			...protectedRouteList.quantitativeIntelligence,
			label: 'Analytics and Optimization',
			subMenu: [
				protectedRouteList.decisionMaking,
				protectedRouteList.simm,
				protectedRouteList.clientScoring,
				{...protectedRouteList.marginAnalysis, label: 'Pre-Trade IM Analytics'},
				protectedRouteList.marginAttribution,
				{...protectedRouteList.collateralOptimization, label: 'Liquidity Optimization'},
				{...protectedRouteList.collateralOptimizationDashboard, label: 'Liquidity Optimization Dashboard'},
			],
		},
		{
			...protectedRouteList.templateManagement,
			subMenu: [{...protectedRouteList.dmnViewer, label: 'DMN'}, protectedRouteList.cusipSchedule],
		},
	],
	[CLIENT_CONSTANTS.STATESTREET_ALP]: [
		{
			...protectedRouteList.execution,
			label: 'SECURITIES LENDING',
			subMenu: [
				{...protectedRouteList.alpStt, isAggregate: false},
				{...protectedRouteList.alpSttClient, isAggregate: false},
				{...protectedRouteList.alpAuctionsInternalMain, isAggregate: false},
				{...protectedRouteList.alpAuctionsBorrowerMain, isAggregate: false},
			],
		},
	],
	[CLIENT_CONSTANTS.DH]: [
		{...protectedRouteList.uploadPortfolio, label: 'Inventory Upload'},
		protectedRouteList.dashboardTest,
		{
			...protectedRouteList.analytics,
			subMenu: [
				protectedRouteList.abcmAnalytics,
				protectedRouteList.abcPortfolioTest,
				protectedRouteList.settlementCalendar,
			],
		},
		{
			...protectedRouteList.quantitativeIntelligence,
			subMenu: [
				protectedRouteList.decisionMaking,
				protectedRouteList.simm,
				protectedRouteList.clientScoring,
				protectedRouteList.collateralOptimization,
				protectedRouteList.collateralOptimizationDashboard,
			],
		},
		{
			...protectedRouteList.portfolioAnalytics,
			subMenu: [
				protectedRouteList.indexAnalysis,
				protectedRouteList.inventoryOptimization,
				protectedRouteList.collateralBasketList,
			],
		},
		{
			...protectedRouteList.execution,
			subMenu: [
				protectedRouteList.peerToPeer,
				protectedRouteList.marginManagement,
				protectedRouteList.needsAvailabilities,
				{...protectedRouteList.group, label: 'Locates'},
				protectedRouteList.alp,
				protectedRouteList.alpInventoryManager,
				protectedRouteList.alpSecurityManager,
				protectedRouteList.alpClient,
				protectedRouteList.sbl,
				protectedRouteList.intradayActivities,
				protectedRouteList.tradeBlotter,
			],
		},
		{
			...protectedRouteList.dataMining,
			subMenu: [protectedRouteList.marketData],
		},
		protectedRouteList.operations,
		{
			...protectedRouteList.pricingTool,
			subMenu: [protectedRouteList.indexPricer, protectedRouteList.breakevenPricer],
		},
		{
			...protectedRouteList.templateManagement,
			subMenu: [
				protectedRouteList.dmnViewer,
				protectedRouteList.cusipSchedule,
				protectedRouteList.lcr,
				protectedRouteList.abpm,
			],
		},
	],
}

export const notificationRoutes = [
	{path: paths.tradingQueue, id: 'tradingQueue'},
	// {path: paths.marketPlace, id: 'p2p'},
	{path: paths.peerToPeer, id: 'p2p'},
	{path: paths.needsAvailabilities, id: 'avn'},
	{path: paths.alp, id: 'locateCounterAuthorizer'},
	{path: paths.alpClient, id: 'locateCounterClient'},
]

export const notificationLabels = ['Execution']
export const repoModuleUrl = [
	'/margin-analysis',
	'/margin-attribution',
	'/alp1-inventory-manager',
	'/alp1',
	'/alp1-client',
	'/alp1-internal-auctions',
	'/alp1-borrower-auctions',
	'/market-place',
]
